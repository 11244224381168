<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <!-- <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert> -->

    <b-row
      v-if="Object.keys(invoice).length"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          ref="invoiceBody"
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h3 class="text-primary ">
                    {{ invoice.invoice_from.name }}
                  </h3>
                </div>
                <p class="card-text mb-25">
                  Office {{ invoice.invoice_from.address.address_line }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.invoice_from.address.address_city+' '+invoice.invoice_from.address.address_province+' '+invoice.invoice_from.address.address_postal_code }}
                </p>
                <p>
                  <router-link
                    class="card-text mb-0"
                    to="mailto: info@onikon.com"
                  >
                    {{ invoice.invoice_from.email }}
                  </router-link>
                </p>
                <p>
                  <router-link
                    class="card-text mb-0"
                    :to="'tel:' + invoice.invoice_from.phone"
                  >
                    {{ invoice.invoice_from.phone }}
                  </router-link>
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Statement
                </h4>

                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Date From:
                  </p>
                  <p class="invoice-date">
                    {{ invoice.general.date.from }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Date To:
                  </p>
                  <p class="invoice-date">
                    {{ invoice.general.date.to }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Invoice To:
                </h6>
                <h6 class="mb-25">
                  {{ invoice.invoice_to.name }}
                </h6>
                <p class="card-text mb-25">
                  {{ invoice.invoice_to.phone }}
                </p>
                <p class="card-text mb-0">
                  {{ invoice.invoice_to.email }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    Payment Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Card Type:
                        </td>
                        <td><span class="font-weight-bold">{{ invoice.payment_detail.card.card_type }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Credit Card:
                        </td>
                        <td><span class="font-weight-bold">{{ invoice.payment_detail.card.card_number }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Expiry Date:
                        </td>
                        <td><span class="font-weight-bold">{{ invoice.payment_detail.card.expired_date }}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <b-table
            responsive
            :items="invoice.items"
          >
            <template #cell(description)="data">
              {{ data.item.description.data[0].description }}
            </template>
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="getVarient(data.value)"
              >
                {{ data.value }}
              </b-badge>
            </template>
          </b-table>
          <div class="totalPriceContainer">
            <div class="invoice-total-wrapper ">
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Total:
                </p>
                <p class="invoice-total-amount">
                  ${{ getTotalPrice }}
                </p>
              </div>
            </div>

          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="download"
          >
            Download
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
    </b-row>
    <pdf
      ref="pdfContainer"
      :invoice="invoice"
    />
    <!-- <invoice-sidebar-send-invoice /> -->
    <!-- <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
// import { ref, onUnmounted } from '@vue/composition-api'
// import store from '@/store'
// import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, VBToggle, BTable, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import VueHtml2pdf from 'vue-html2pdf'
import pdf from './components/statementPdf.vue'
// import jsPDF from 'jspdf'
// import html2canvas from 'html2canvas'
// import invoiceStoreModule from '../invoiceStoreModule'
// import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
// import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'
// import VueHtml2pdf from 'vue-html2pdf'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTable,
    BButton,
    BBadge,
    // VueHtml2pdf,
    pdf,
    // InvoiceSidebarAddPayment,
    // InvoiceSidebarSendInvoice,
  },
  data() {
    return {
      invoice: {},
    }
  },
  computed: {
    getTotalPrice() {
      return this.invoice.general.total / 100
    },
  },
  created() {
    console.log('created')
    const data = {
      from: this.$route.query.from,
      to: this.$route.query.to,
    }
    console.log(data)
    this.$store.dispatch('billings/getStatement', data).then(response => {
      console.log(response)
      this.invoice = response.data
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    printInvoice() {
      window.print()
    },
    download() {
      this.$refs.pdfContainer.$refs.html2Pdf.generatePdf()
    },
    // download() {
    //   console.log('download')
    //   // eslint-disable-next-line new-cap
    //   const doc = new jsPDF()
    //   /** WITH CSS */
    //   const canvasElement = document.createElement('canvas')
    //   html2canvas(this.$refs.invoiceBody, { canvas: canvasElement }).then(canvas => {
    //     const img = canvas.toDataURL('image/jpeg', 0.8)
    //     doc.addImage(img, 'JPEG', 200, 200)
    //     doc.save('sample.pdf')
    //   })
    // },
    getVarient(status) {
      switch (status) {
        case 'Paid': return 'success'
        case 'Unpaid': return 'danger'
        default: return 'dark'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  .content-header-left{
    display:none;
  }
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
.totalPriceContainer{
    padding:2rem;
}
</style>
