<template>
  <div class="component">
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="getFileName"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <b-card
          ref="invoiceBody"
          no-body
          v-if="Object.keys(invoice).length"
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h3 class="text-primary ">
                    {{ invoice.invoice_from.name }}
                  </h3>
                </div>
                <p class="card-text mb-25">
                  Office {{ invoice.invoice_from.address.address_line }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.invoice_from.address.address_city+' '+invoice.invoice_from.address.address_province+' '+invoice.invoice_from.address.address_postal_code }}
                </p>
                <p>
                  <router-link
                    class="card-text mb-0"
                    to="mailto: info@onikon.com"
                  >
                    {{ invoice.invoice_from.email }}
                  </router-link>
                </p>
                <p>
                  <router-link
                    class="card-text mb-0"
                    :to="'tel:' + invoice.invoice_from.phone"
                  >
                    {{ invoice.invoice_from.phone }}
                  </router-link>
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Statement
                </h4>

                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Date From:
                  </p>
                  <p class="invoice-date">
                    {{ invoice.general.date.from }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Date To:
                  </p>
                  <p class="invoice-date">
                    {{ invoice.general.date.to }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Invoice To:
                </h6>
                <h6 class="mb-25">
                  {{ invoice.invoice_to.name }}
                </h6>
                <p class="card-text mb-25">
                  {{ invoice.invoice_to.phone }}
                </p>
                <p class="card-text mb-0">
                  {{ invoice.invoice_to.email }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    Payment Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Card Type:
                        </td>
                        <td><span class="font-weight-bold">{{ invoice.payment_detail.card.card_type }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Credit Card:
                        </td>
                        <td><span class="font-weight-bold">{{ invoice.payment_detail.card.card_number }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Expiry Date:
                        </td>
                        <td><span class="font-weight-bold">{{ invoice.payment_detail.card.expired_date }}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <b-table
            responsive
            :items="invoice.items"
          >
            <template #cell(description)="data">
              {{ data.item.description.data[0].description }}
            </template>
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="getVarient(data.value)"
              >
                {{ data.value }}
              </b-badge>
            </template>
          </b-table>
          <div class="totalPriceContainer">
            <div class="invoice-total-wrapper ">
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Total:
                </p>
                <p class="invoice-total-amount">
                  ${{ getTotalPrice }}
                </p>
              </div>
            </div>

          </div>
        </b-card>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, VBToggle, BBadge, BTable,
} from 'bootstrap-vue'
import VueHtml2pdf from 'vue-html2pdf'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTable,
    BBadge,
    // VueHtml2pdf,
    VueHtml2pdf,
    // InvoiceSidebarAddPayment,
    // InvoiceSidebarSendInvoice,
  },
  props: {
    invoice: Object,
  },
  computed: {
    getTotalPrice() {
      return this.invoice.general.total / 100
    },
    getFileName() {
      if (this.invoice.invoice_to) {
        return 'ONIKON Creative IDX Invoice for ' + this.invoice.invoice_to.name + ' | ' + this.invoice.general.date.from + ' ~ ' + this.invoice.general.date.to
      }
      return null
    },
  },
  methods: {
    getVarient(status) {
      switch (status) {
        case 'Paid': return 'success'
        case 'Unpaid': return 'danger'
        default: return 'dark'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
.invoice-preview .invoice-padding{
    padding-left:2rem;
    padding-right:2rem;
}
.invoice-preview .invoice-total-wrapper .invoice-total-item, .invoice-edit .invoice-total-wrapper .invoice-total-item, .invoice-add .invoice-total-wrapper .invoice-total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.totalPriceContainer{
    padding:0;
}
.invoice-padding-pdf{
    padding-left:2.5rem;
    padding-right:2.5rem;
}
.invoice-total-wrapper-pdf{
    max-width:12rem;
    width:100%;
}
.invoice-total-item-pdf{
    display:flex;
    justify-content:space-between;
}
</style>
